import React from "react"
import Container from "../../components/UI/ContainerTwo"
import Heading from "../../components/Heading"
import Text from "../../components/Text"

const Terminos = () => {
  return (
    <Container>
      <Text
        content="La utilización del sitio confiere la condición de usuario y conlleva la aceptación plena y sin reservas de todas y cada una de las Condiciones Generales de Uso, desde el momento mismo del acceso al sitio. En consecuencia, el hecho de hacer uso de esta página web, constituye la aceptación a las presentes condiciones de uso. La utilización de ciertos servicios ofrecidos a los Usuarios a través del sitio, se encuentra sometida a condiciones particulares, propias que, según los casos, sustituyen, completan y/o modifican las Condiciones Generales (en adelante las Condiciones Particulares), por lo que su uso constituye la aceptación a las condiciones particulares.

Estas condiciones constituyen un acuerdo completo entre el usuario y SEGGASA respecto al uso de ésta página web, y prevalece y reemplaza a otras comunicaciones acuerdos o propuestas verbales o escritas entre el usuario y la página web respecto únicamente del tema en cuestión. SEGGASA se reserva el derecho de modificar los Términos y Condiciones generales de Uso del Sitio en cualquier momento, notificando al usuario la nueva versión con las modificatorias insertas mediante la publicación en la página Web, presumiéndose la aceptación de las mismas, en caso de continuar el usuario con la utilización del servicio."
      />
      <Heading as="h5" content="Sobre el Acceso y Uso" />
      <Text content="Esta Página Web es para uso personal, por lo que el usuario no se encuentra autorizado a modificar, copiar, distribuir, divulgar, transmitir, utilizar, reproducir, publicar, ceder, vender la información, el software, los productos o servicios que pueda obtener de la página web. La prestación del servicio de sitio por parte de SEGGASA tiene carácter de gratuito para los usuarios y no exige la previa suscripción o registro del usuario, salvo indicación en contrario. El Usuario se compromete a utilizar el sitio y los servicios de conformidad con la ley, la moral y las buenas costumbres. El Usuario se obliga a abstenerse de utilizar el sitio y los servicios con fines o efectos ilícitos, contrarios a lo establecido en estas Condiciones Generales, lesivos de los derechos e intereses de terceros, que de cualquier forma puedan dañar, inutilizar, sobrecargar o deteriorar el sitio y los servicios o impedir la normal utilización o disfrute del Sitio y de los servicios por parte de los usuarios." />
      <Heading as="h5" content="Sobre el Contenido" />
      <Text content="Si desea obtener información sobre cómo obtener el permiso de SEGGASA para la utilización de los Contenidos, el Usuario deberá contactarse con nosotros En caso de producirse violación a cualquiera de estos términos, el permiso para la utilización de los contenidos terminará automáticamente, debiendo el Usuario proceder inmediatamente a destruir las copias que haya realizado del material obtenido. El Usuario se obliga a usar los contenidos de forma diligente, correcta y licita, absteniéndose de utilizarlos con fines contrarios a la ley, el orden público, la moral y las buenas costumbres." />
      <Heading as="h5" content="Sobre la Propiedad Intelectual" />
      <Text
        content="Los derechos de propiedad intelectual respecto de los servicios, contenidos, signos distintivos y dominio del sitio; así como, los derechos de uso y explotación de los mismos, incluyendo su divulgación, publicación, reproducción, distribución y transformación son propiedad exclusiva de SEGGASA. El Usuario no adquiere ningún derecho por el simple uso de los servicios y contenidos del sitio; y en ningún momento dicho uso será considerado como una autorización y/o licencia para la utilización de los mismos con fines distintos a los que se contemplan en los presentes términos y condiciones de uso.

"
      />
      <Heading as="h5" content="Sobre Propiedad Intelectual de Terceros" />
      <Text content="El Usuario acuerda, que las disposiciones establecidas en el párrafo anterior, respecto a la titularidad de los derechos de SEGGASA, también son aplicables a los derechos de terceros, respecto de los servicios y contenidos de las páginas enlazadas al sitio." />
      <Heading as="h5" content="Sobre el Uso de Servicios" />
      <Text content="SEGGASA reserva algunos de los servicios ofrecidos a través del sitio, a los usuarios registrados mediante el correspondiente llenado del registro de Usuarios de SEGGASA, a disposición de los Usuarios que deseen registrarse. Los usuarios se comprometen a seleccionar, usar y conservar su nombre de Usuario y su contraseña." />
      <Heading as="h5" content="Sobre la Asignación de las Claves de Acceso" />
      <Text content="SEGGASA asignará las claves de acceso, las que serán comunicadas a través de e-mails a sus respectivos usuarios. El Usuario podrá en cualquier momento cambiarlas por cualesquiera otras; sin embargo no podrá elegir palabras, expresiones o conjunto grafico-denominativos malsonantes, injuriosos, coincidentes con marcas, nombres comerciales, rótulos de establecimientos, denominaciones sociales, expresiones publicitarias, nombres y seudónimos de personajes de relevancia pública o famosos para cuya utilización no esté autorizado y en general, contrarios a la ley, el orden público, la moral y las buenas costumbres." />
      <Heading as="h5" content="Sobre Uso y Custodia" />
      <Text content="El usuario se compromete a hacer un uso diligente de las claves de acceso, así como a no poner a disposición de terceros sus claves de acceso. El usuario se compromete a comunicar a SEGGASA, a la brevedad posible la pérdida o robo de las claves de acceso, así como cualquier riesgo de acceso a las mismas por un tercero." />
    </Container>
  )
}

export default Terminos
